<template>
  <section>
    <div
      class="
        d-flex
        flex-row
        justify-content-between
        align-items-center
        container
      "
    >
      <div>
        <h3 class="pl-2 card-title">
          Total de Nps: <b>{{ qtdNps }}</b>
        </h3>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    qtdNps: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped>
.container {
  padding-left: 0px;
}
</style>